import { Form, Formik } from "formik";
import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { productosService } from "../../../services";

function VerCategoriaScreen(props) {
    const { loadingActionProps } = props;
    const params = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = "Ver Categoria - MiPosDigital"
    }, []);

    React.useEffect(() => {
        if (params && params.id) {
            getCategoriaById(params.id);
        }

    }, [params.id]);

    const [initialValues, setInitialValues] = React.useState({ nombre: '', estado: true })

    const getCategoriaById = async (id) => {
        const result = await productosService.ObtenerCategoriasById(id);
        setInitialValues({ nombre: result.nombre, estado: result.visible })
    }

    const submit = async (values, resetForm) => {
        loadingActionProps(true);
        let isTrueSet = (values.estado === 'true');
        const payload = {
            nombre: values.nombre,
            visible: isTrueSet,
        }
        try {
            const result = await productosService.ActualizarCategoriasById(params.id, payload);
            console.log("mostrando result", result)
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Categoría actualizada con éxito' })
            setTimeout(() => { navigate('/categorias') }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al actualizar la categoría' })
            loadingActionProps(false);
        }

    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    <h2>Editar Categoria</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={async (values, { resetForm }) => {
                                    submit(values, resetForm);

                                }}
                            >
                                {({ values, handleChange, handleBlur }) => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Nombre</label>
                                                    <input type="text" className="form-control" name="nombre" value={values.nombre} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Estado de la categoría</label>
                                                    <select className="form-control" name="estado" onChange={handleChange} value={values.estado}>
                                                        {values?.estado == '' && <option>Seleccionar</option>}
                                                        <option value={true} >Activa</option>
                                                        <option value={false}>Inactiva</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button type="submit" className="btn btn-primary border-0">Guardar Categoria</button>
                                            </Col>
                                        </Row>


                                    </Form>
                                )}
                            </Formik>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerCategoriaScreen);