import React from 'react'
import {useFormik} from 'formik';
import { clientesModel } from '../../models';
import { personasService } from '../../services';
import {connect} from 'react-redux';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
function ClientesComponent(props) {

    const {getClientes,setIsShowing,loadingActionProps} = props;

    const submit = async (payload) => {
        console.log(payload);
        loadingActionProps(true);
        const result = await personasService.CrearCliente(payload);
        loadingActionProps(false);
        getClientes();
        setIsShowing(false);
      }
    
      const { values, touched,  errors, handleSubmit, handleChange, handleBlur, setFieldValue, setValues } = useFormik({
        initialValues: clientesModel,
        onSubmit: values =>  submit(values)
    });

  return (
    <form onSubmit={handleSubmit}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Tipo Documento</label>
                        <select className="form-control" onChange={handleChange} name="tipoDocumento">
                            <option>Seleccionar</option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="PASS">Pasaporte</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Numero Documento</label>
                        <input type="text" className="form-control" onChange={handleChange} name="numeroDocumento" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Nombre</label>
                        <input type="text" className="form-control" onChange={handleChange} name="nombre" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Telefono</label>
                        <input type="text" className="form-control" onChange={handleChange} name="telefono" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" onChange={handleChange} name="email" />
                    </div>
                </div>
                <div className="col-lg-12 my-3">
                <button type="submit" className="btn btn-success">Crear</button>
                </div>
                
            </div>
        </div>
    </form>

  )
}

const mapStateToProps= (state) =>{
    return {
        pos: state.pos,
        user: state.user,
    }
  };
  
  const mapDispatchToProps= (dispatch) =>{
    return {
      abrirCajaActionProps: (data) => {dispatch(abrirCajaAction(data))},
      loadingActionProps: (data)=>{dispatch(loadingAction(data))}
  }
  }
  
export default connect(mapStateToProps,mapDispatchToProps)(ClientesComponent);