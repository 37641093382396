import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { connect } from "react-redux";
import { useRoutes } from "react-router-dom";
import './App.css';
//Layouts
import DefaultLayout from "./layouts/default";
//Private Route
import PrivateRoute from './routes/PrivateRoute';
//Public Route
import PublicRoute from './routes/PublicRoute';
// Views
import Dashboard from "./screens/dashboard";
import Login from "./screens/login";
import VentasScreen from "./screens/ventas";
import Mesas from "./screens/ventas/mesas";

import AbrirCajaComponent from './components/caja';
import CategoriasScreen from "./screens/categorias";
import NuevaCategoriaScreen from "./screens/categorias/nuevo";
import VerCategoriaScreen from "./screens/categorias/ver";
import ClientesScreen from "./screens/clientes";
import ConfiguracionScreen from "./screens/configuracion";
import MovimientosInventarioScreen from './screens/inventario/';
import NuevoMovimientoInventario from "./screens/inventario/nuevo";
import VerMovimientoInventario from "./screens/inventario/ver";
import MesasScreen from "./screens/mesas";
import NuevaMesaScreen from "./screens/mesas/nuevo";
import VerMesasScreen from "./screens/mesas/ver";
import MeserosScreen from "./screens/meseros";
import NuevoMeseroScreen from "./screens/meseros/nuevo";
import ProductosScreen from "./screens/productos";
import ImportarProductos from "./screens/productos/importar";
import NuevoProductoScreen from "./screens/productos/nuevo";
import VerProductoScreen from "./screens/productos/ver";
import FacturasScreen from "./screens/ventas/facturas";
import ZonasScreen from "./screens/zonas";
import NuevaZonaScreen from "./screens/zonas/nuevo";
import VerZonaScreen from "./screens/zonas/ver";

function App(props) {
  const { isAuthenticated, loading } = props;

  const mainRoutes = {
    path: '/',
    element: <PrivateRoute isAuthenticated={isAuthenticated} ><DefaultLayout /></PrivateRoute>,
    children: [
      { path: 'dashboard', element: <PrivateRoute isAuthenticated={isAuthenticated} ><Dashboard /></PrivateRoute> },
      { path: 'ventas', element: <PrivateRoute isAuthenticated={isAuthenticated} ><Mesas /></PrivateRoute> },
      { path: 'ventas/facturas', element: <PrivateRoute isAuthenticated={isAuthenticated} ><FacturasScreen /></PrivateRoute> },
      { path: 'pos', element: <PrivateRoute isAuthenticated={isAuthenticated} loading={loading} ><VentasScreen /></PrivateRoute> },
      { path: 'personas/clientes', element: <PrivateRoute isAuthenticated={isAuthenticated} ><ClientesScreen /></PrivateRoute> },
      { path: 'personas/meseros', element: <PrivateRoute isAuthenticated={isAuthenticated} ><MeserosScreen /></PrivateRoute> },
      { path: 'personas/meseros/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevoMeseroScreen /></PrivateRoute> },
      { path: 'productos', element: <PrivateRoute isAuthenticated={isAuthenticated} ><ProductosScreen /></PrivateRoute> },
      { path: 'productos/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevoProductoScreen /></PrivateRoute> },
      { path: 'productos/ver/:id', element: <PrivateRoute isAuthenticated={isAuthenticated} ><VerProductoScreen /></PrivateRoute> },
      { path: 'productos/importar', element: <PrivateRoute isAuthenticated={isAuthenticated} ><ImportarProductos /></PrivateRoute> },
      { path: 'inventario/movimientos', element: <PrivateRoute isAuthenticated={isAuthenticated} ><MovimientosInventarioScreen /></PrivateRoute> },
      { path: 'inventario/movimientos/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevoMovimientoInventario /></PrivateRoute> },
      { path: 'inventario/movimientos/ver/:id', element: <PrivateRoute isAuthenticated={isAuthenticated} ><VerMovimientoInventario /></PrivateRoute> },
      { path: 'categorias', element: <PrivateRoute isAuthenticated={isAuthenticated} ><CategoriasScreen /></PrivateRoute> },
      { path: 'categorias/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevaCategoriaScreen /></PrivateRoute> },
      { path: 'categorias/ver/:id', element: <PrivateRoute isAuthenticated={isAuthenticated} ><VerCategoriaScreen /></PrivateRoute> },
      { path: 'caja', element: <PrivateRoute isAuthenticated={isAuthenticated} ><AbrirCajaComponent /></PrivateRoute> },
      { path: 'configuracion', element: <PrivateRoute isAuthenticated={isAuthenticated} ><ConfiguracionScreen /></PrivateRoute> },
      { path: 'mesas', element: <PrivateRoute isAuthenticated={isAuthenticated} ><MesasScreen /></PrivateRoute> },
      { path: 'mesas/ver/:id', element: <PrivateRoute isAuthenticated={isAuthenticated} ><VerMesasScreen /></PrivateRoute> },
      { path: 'mesas/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevaMesaScreen /></PrivateRoute> },
      { path: 'zonas', element: <PrivateRoute isAuthenticated={isAuthenticated} ><ZonasScreen /></PrivateRoute> },
      { path: 'zonas/ver/:id', element: <PrivateRoute isAuthenticated={isAuthenticated} ><VerZonaScreen /></PrivateRoute> },
      { path: 'zonas/nuevo', element: <PrivateRoute isAuthenticated={isAuthenticated} ><NuevaZonaScreen /></PrivateRoute> },

    ],
  };



  const sessionRoutes = {
    path: '/login',
    element: <PublicRoute isAuthenticated={isAuthenticated}><Login /></PublicRoute>,
  };

  const routing = useRoutes([mainRoutes, sessionRoutes]);
  return (
    <>{routing}</>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    lading: state.loading
  }
};

export default connect(mapStateToProps, null)(App);
