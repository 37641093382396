import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../redux/actions";
import { productosService } from "../../services";


function ProductosScreen(props) {

    const [productos, setProductos] = React.useState(null);
    const [categorias, setCategorias] = React.useState(null);

    React.useEffect(() => {
        getProducts();
        getCategorias();
    }, []);

    const getProducts = async () => {
        const result = await productosService.ObtenerProductos();
        setProductos(result);
    }

    const getCategorias = async () => {
        const result = await productosService.ObtenerCategorias();
        setCategorias(result);
    }

    const filtrarProductos = async (e, tipo) => {
        const value = e.target.value;
        if (value != "Todas" && value != "") {
            if (tipo == 'Nombre') {
                const result = await productosService.FiltrarProductosByNombre(value)
                setProductos(result);
            }
            if (tipo == 'Categoria') {
                const result = await productosService.FiltrarProductosByCategoria(value)
                setProductos(result);
            }
            if (tipo == 'Tipo') {
                const result = await productosService.FiltrarProductosByTipo(value)
                setProductos(result);
            }
        } else {
            getProducts();
        }


    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Productos</h2>
                </Col>
                <Col lg={8} className="text-end">
                    <Link className="btn btn-primary border-0 m-2" to={`/productos/nuevo`} >Nuevo Producto</Link>
                    <Link className="btn btn-primary border-0 m-2" to={`/productos/importar`} >Importar</Link>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th><input type="text" className="form-control" placeholder="Filtrar por nombre" onBlur={(e) => filtrarProductos(e, "Nombre")} /></th>
                                            <th>
                                                <select className="form-control" onChange={(e) => filtrarProductos(e, "Categoria")}>
                                                    <option value="Todas">TODAS</option>
                                                    {categorias && categorias.map((data, index) => {
                                                        return <option key={index} value={data.id}>{data.nombre}</option>
                                                    })}

                                                </select>
                                            </th>
                                            <th>
                                                <select className="form-control" onChange={(e) => filtrarProductos(e, "Tipo")}>
                                                    <option value="Todas">TODAS</option>
                                                    <option value="Servicio">Servicio</option>
                                                    <option value="Estandar">Estandar</option>
                                                    <option value="Combinacion">Combinacion</option>

                                                </select>
                                            </th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Categoria</th>
                                            <th>Tipo</th>
                                            <th>Precio Venta</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productos && productos.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.codigoProducto}</td>
                                                    <td>{data.nombre}</td>
                                                    <td>{data.categoria?.nombre}</td>
                                                    <td>{data.tipo}</td>
                                                    <td>{data.precioVenta.toLocaleString('es')}</td>
                                                    <td>
                                                        <Link className="btn btn-secondary border-0 btn-xs" to={`/productos/ver/${data.id}`} ><i className="fa fa-pencil"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductosScreen);